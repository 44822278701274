//import './TypeAheadDropDown.css'
import React from 'react';
import { Text, TextField } from '@fluentui/react';
import { withTranslation, WithTranslation } from 'react-i18next';
import client from '../../api';
import axios from 'axios';
import _ from 'lodash';
import { Icon } from '@fluentui/react/lib/Icon';
import { LocationAddress } from '../../types/projectDefinition';
import ENV from '../../../env.json';

interface Props extends WithTranslation {
  onAddressSelected: (address: LocationAddress) => void;
  address: string;
  lable?: string | undefined;
  disabled?: boolean;
  required?: boolean;
  onChange:(value: any) => void;
}

interface State {
  suggestions: any[];
  text: string;
  isShowSuggetions: boolean
}

class AzureAddressAutoComplete extends React.Component<Props, State> {
  ulRef: React.RefObject<HTMLUListElement>;

  constructor(props: Props) {
    super(props);
    this.ulRef = React.createRef();
    this.state = {
      suggestions: [],
      text: this.props.address,
      isShowSuggetions: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.address != prevProps.address) {
      this.setState({ text: this.props.address });
    }
  }

  onChangeNameInput = async (field: string) => {
    let value: string = field!!;
    let cancelTokenSource: any = null;
    if (cancelTokenSource && cancelTokenSource.token) {
      cancelTokenSource.cancel();
    }
    cancelTokenSource = axios.CancelToken.source();
    await client
      .get(
        `https://atlas.microsoft.com/search/address/json?subscription-key=${ENV.MAP_KEY}&api-version=1.0&query=${value}`,
        {
          cancelToken: cancelTokenSource.token,
        },
      )
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.results &&
          _.isArray(response.data.results)
        ) {
          console.log('response.data.results', response.data.results)
          this.setState({
            isShowSuggetions: true,
            suggestions: response.data.results,
          });
        }
      });
  };

  suggestionSelected = (address: LocationAddress) => {
    console.log('address', address)
    this.props.onAddressSelected(address);
    this.setState(() => ({
      text: address.address.freeformAddress,
      suggestions: [],
    }));
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul
        style={{
          zIndex: 100,
          position: 'absolute',
          marginLeft: -40,
          marginTop: 0,
          width:'100%'
        }}
        ref={this.ulRef}
      >
        {suggestions.map((address) => (
          <li
            key={address.id}
            onMouseDown={(e) => {
              this.suggestionSelected(address);
            }}
            style={{
              background: '#F8F9F9',
              zIndex: 100,
              position: 'relative',
              padding: 5,
              cursor: 'pointer',
            }}
          >
            <Icon
              iconName="MapPinSolid"
              style={{ marginBottom: -2, paddingRight: 4 }}
            />
            <Text>
              {address.address.freeformAddress}
            </Text>
          </li>
        ))}
      </ul>
    );
  };

  handleKeyDown = (event: any) => {

  };

  render() {
    const { text } = this.state;
    const { t } = this.props;

    return (
      <div style={{ zIndex: 100, position: 'relative' }}>
        <TextField
          label={this.props.lable ? this.props.lable : undefined}
          placeholder={t('address')}
          onChange={(e: any) => {
            this.onChangeNameInput(e.target!!.value);
            this.setState({
              text: e.target!!.value,
            });
            this.props.onChange(e.target!!.value);
          }}
          onBlur={() => {
            this.setState({ isShowSuggetions: false });
          }}
          onFocus={() => {
            this.setState({ isShowSuggetions: true });
          }}
          onKeyDown={() => {
            this.ulRef.current?.focus();
          }}
          value={text}
          autoComplete={'off'}
          disabled={this.props.disabled}
          required={this.props?.required}
        />
        {this.state.isShowSuggetions && this.renderSuggestions()}
      </div>
    );
  }
}

export default withTranslation()(AzureAddressAutoComplete);
