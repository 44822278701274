import { DatePicker, Dropdown, getTheme, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Label, mergeStyleSets, Modal, PrimaryButton, Stack, TeachingBubble, TextField } from '@fluentui/react'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next'
import client from '../../../../api';
import { formatDateTimeToISOString, PMOL_PBS_HEADER_SECTION_LIST } from '../../../../shared/util';
import { readPMOLByID } from '../../../../reducers/pmolReducer';
import { useDispatch } from 'react-redux';
import { uPrinceTheme } from '../../../../../theme';
import { CREATE_LOCATION_EP } from '../../../../shared/endpoints';
import ReactAzureMap from '../../../ProjectBreakdownStructure/product/reactAzureMap/ReactAzureMap';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import AzureAddressAutoComplete from '../../../../shared/azureAddress/container'

interface ExtraWorkData {
  id: string | null,
  description: null,
  pmolid: null,
  containername: null,
  originalblobname: null,
  originalbloburl: string,
  sectionheading: string | null,
  newblobname: string,
  phase: string,
  location: string,
  createdDate: Date,
  renameExtention: string // should be omitted
}

interface Location {
  freeFormAddress: string,
  id: string | null,
  pos: {
    lat: string,
    lon: string
  }
}

const theme = getTheme()

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
};

const classNames = mergeStyleSets({
  fullWidth: { width: '100%' },
  fields: {
    display: "flex",
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: '6px'
  },
  textField: {
    width: '70%',
    marginBottom: '8px',
    height: 32,
    margin: 0
  },
  renameFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 0'
  },
  formContainer: {
    padding: '10px 24px',
    marginTop: '10px'
  },
  errorMsg: {
    fontSize: 12,
    color: 'red'
  },
  label: {
    width: '30%'
  },
  diabled: {
    backgroundColor: '#bebebe60'
  }
})

const mapWhiteIconButtonStyles = {
  root: { color: uPrinceTheme.palette.white },
  rootHovered: { color: theme.palette.neutralDark },
};

const mapIconWhite: IIconProps = {
  iconName: 'Nav2DMapView',
  styles: mapWhiteIconButtonStyles,
};


const PreviewProperties = (props: {
  renameValueExtention?: any;
  data: any,
  selectedImageDetailsForUpdate?: any;
  sectionheading: any;
  setIsOpenImgRenameModal?: Dispatch<SetStateAction<boolean>> | undefined,
  bulkEdit?: {
    show: boolean,
    selectedImages: any[]
  };
  setBulkEdit?: Dispatch<SetStateAction<boolean>> | undefined,
  renameSuccess?: any
  projectMoleculeId?: any
  pmolDetails: {
    project: string | undefined;
    product: string | undefined,
    pmol: string | undefined;
    foreman: string | undefined;
    location: {
      freeFormAddress: string | undefined;
      lat: string | undefined;
      lon: string | undefined;
      id: any
    }
  },
  isProduct: boolean
}) => {
  const initialData: ExtraWorkData = {
    id: null,
    description: null,
    pmolid: null,
    containername: null,
    originalblobname: null,
    originalbloburl: "",
    sectionheading: null,
    newblobname: "",
    phase: "",
    location: "",
    createdDate: new Date(),
    renameExtention: ""
  }
  const {t} = useTranslation();
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<ExtraWorkData>(initialData);
  const [bulkFormData, setBulkFormData] = useState<any[]>([]);
  const [showMap, setShowMap] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)

  const [location, setLocation] = useState<Location>({
    freeFormAddress: "",
    id: null,
    pos: {
      lat: "",
      lon: ""
    }
  })
  const [createdDate, setCreatedDate] = useState<Date | undefined | null>(undefined);
  const [selectedPhaseInBulk, setSelectedPhaseInBulk] = useState<string | null>(null)

  useEffect(() => {
    if (!props.bulkEdit?.show) {
      if (props.selectedImageDetailsForUpdate) {
        setLocation({
          id: props.selectedImageDetailsForUpdate?.location,
          freeFormAddress: props.selectedImageDetailsForUpdate?.freeFormAddress ? props.selectedImageDetailsForUpdate?.freeFormAddress : "",
          pos: {
            lat: props.selectedImageDetailsForUpdate?.lat ? props.selectedImageDetailsForUpdate?.lat : "0",
            lon: props.selectedImageDetailsForUpdate?.lon ? props.selectedImageDetailsForUpdate?.lon : "0"
          }
        })
      }
    }
  }, [props.bulkEdit?.show, props.selectedImageDetailsForUpdate])
  
  useEffect(() => {
    if (!props.bulkEdit?.show) {
      if (props.selectedImageDetailsForUpdate) {
        setFormData({
          id: props.selectedImageDetailsForUpdate?.id,
          description: null,
          pmolid: null,
          containername: null,
          originalblobname: null,
          originalbloburl: props.selectedImageDetailsForUpdate?.image,
          sectionheading: props.sectionheading,
          newblobname: props.selectedImageDetailsForUpdate?.renameValue,
          phase: props.selectedImageDetailsForUpdate?.phase,
          createdDate: props.selectedImageDetailsForUpdate?.createdDate,
          location: props.selectedImageDetailsForUpdate?.location,
          renameExtention: props.selectedImageDetailsForUpdate?.renameValueExtention
        })
      }
    } else {
      if (props.bulkEdit?.selectedImages) {
        setCreatedDate(undefined);
        setFormData(initialData)
        setLocation({
          freeFormAddress: "",
          id: null,
          pos: {
            lat: "",
            lon: ""
          }
        })
        const bulkData = props.bulkEdit?.selectedImages?.map((each) => {
          return {
            id: each?.id,
            description: null,
            pmolid: null,
            containername: null,
            originalblobname: null,
            originalbloburl: each?.image,
            sectionheading: props.sectionheading,
            newblobname: null,
            phase: each?.phase,
            location: each?.location,
            createdDate: createdDate ? createdDate : null,
          }
        })
        setBulkFormData(bulkData)
      }
    }
  }, [props.selectedImageDetailsForUpdate, props.bulkEdit?.show])

  const onUpdate = async (values: any) => {
    setLoader(true)
    const {renameExtention, image, ...rest} = values
    const finalPayload = {
      ...rest,
      location: location?.id
    }
    if(
      finalPayload?.id && 
      finalPayload?.originalbloburl && 
      finalPayload?.newblobname && 
      finalPayload?.createdDate 
      // && 
      // finalPayload?.location 
    ) {
      await client.post("/Pmol/RenameBlobFile", [finalPayload]).then((response: any) => {
        if(props.renameSuccess){
          props.renameSuccess(finalPayload);
        }
      }).catch((err) => {
        console.log('err', err)
        alert('Something went wrong while updating a image properties')
      }).finally(() => {
        props.setIsOpenImgRenameModal && props.setIsOpenImgRenameModal(false);
        props.setBulkEdit && props.setBulkEdit(false)
        setLoader(false)
      })
    }
  }

  const onBulkUpdate = async () => {
    if (createdDate) {
      setLoader(true)
      const payload = bulkFormData?.map((e) => {
        return {
          ...e,
          createdDate: createdDate,
          location: location?.id,
          phase: selectedPhaseInBulk
        }
      })
      await client.post("/Pmol/RenameBlobFile", payload).then(async (response: any) => {
        if (response?.status === 200) {
          await dispatch(readPMOLByID(props.projectMoleculeId))
        }
      }).catch((err) => {
        console.log('err', err);
        alert('Something went wrong while updating a image properties')
      }).finally(() => {
        props.setIsOpenImgRenameModal && props.setIsOpenImgRenameModal(false);
        props.setBulkEdit && props.setBulkEdit(false)
        setLoader(false)
        // setDateValidation(false);
      })
    } 
    else {
      // setDateValidation(true)
    }
  }

  const handleLocationPicked = async (pos: any, address: any) => {
    const addressInfo = address?.addresses?.[0]?.address
    const boundingBox = address?.addresses?.[0]?.address?.boundingBox
    const payload: any = {
      type: "Country",
      id: address?.addresses?.[0]?.id,
      score: '1',
      address: addressInfo,
      position: {
        lat: pos?.[0],
        lon: pos?.[1]
      },
      boundingBox: boundingBox,
      viewport: null
    }
    await client.post(CREATE_LOCATION_EP, payload).then((response) => {
      setLocation({
        id: response?.data?.result,
        freeFormAddress: addressInfo?.freeformAddress,
        pos: payload?.position
      })
      setShowMap(false);
    }).catch((err) => {
      console.log('err', err)
      alert('Something went wrong while creating location')
    })
  }

  const handleLocationPickedFromField = async (address: any) => {
    const payload: any = address
    await client.post(CREATE_LOCATION_EP, payload).then((res) => {
      setLocation({
        id: res?.data?.result,
        freeFormAddress: address?.address?.freeformAddress,
        pos: payload?.position
      })
      setShowMap(false)
    }).catch((err) => {
      console.log('err', err)
      alert('Something went wrong while creating location')
    })
  }

  const getPosition = (lat: any, lon: any) => {
    if (lat && lon) {
      if (lat !== '0' && lon !== '0') {
        return [Number(lat), Number(lon)]
      }
    }

    return [4.3452, 50.8373]
  }

  const getPositionValues = (pos: any) => {
    if (pos) {
      if (pos !== '0') {
        return pos
      }
    }
    return null
  }
  
  return (
    <Form 
      onSubmit={onUpdate}
      initialValues={formData}
      validate={values => {
        const errors: any = {};
        if (!values?.newblobname) {
          errors.newblobname = t('nameRequired');
        }

        if (!values?.location) {
          errors.location = t('locationRequired')
        }

        if (!values?.createdDate) {
          errors.createdDate = t('createdDateRequired')
        }
        
        return errors
      }}
      render={({
        handleSubmit,
        values,
        dirty,
        valid,
        errors
      }) => {
        return (
          <>
            <ProgressBar show={loader}/>
            <form className={classNames.formContainer}>
              {!props.bulkEdit?.show && 
                <>
                  {/* Phases  */}
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                        <Label className={classNames.label}>{t('phase')}</Label>
                        <Dropdown 
                          options={[
                            {key: "Before", text: t("before")},
                            {key: "During", text: t("during")},
                            {key: "After", text: t("after")}
                          ]}
                          selectedKey={values?.phase}
                          className={`${classNames.textField}`}
                          onChange={(ev: any, option: any) => {
                            // console.log('option', option)
                            setFormData((prev) => ({
                              ...prev,
                              phase: option?.key
                            }))
                          }}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  {/* File Name  */}
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                        <Label className={classNames.label}>{t('File Name')}</Label>
                        <TextField
                          value={values?.newblobname}
                          className={classNames.textField}
                          name='newblobname'
                          required
                          suffix={'.jpg'}
                          onChange={(event, text) => {
                            setFormData((prev: any) => ({
                              ...prev,
                              newblobname: text
                            }))
                          }}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                  
                  {/* Project */}
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                        <Label className={classNames.label}>{t('project')}</Label>
                        <TextField
                          value={props.pmolDetails?.project}
                          className={classNames.textField}
                          // name='productTitle'
                          readOnly
                          style={{background: '#bebebe80', fontWeight: 'bold'}}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  {/* Product */}
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                        <Label className={classNames.label}>{t('product')}</Label>
                        <TextField
                          value={props.pmolDetails?.product}
                          className={classNames.textField}
                          // name='productTitle'
                          readOnly
                          style={{background: '#bebebe80', fontWeight: 'bold'}}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  {/* PMOL Name */}
                  {!props.isProduct &&
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                          <Label className={classNames.label}>{t('pmolName')}</Label>
                          <TextField
                            value={props.pmolDetails?.pmol}
                            disabled={props.isProduct}
                            className={classNames.textField}
                            // name='pmolTitle'
                            readOnly
                            style={{background: '#bebebe80', fontWeight: 'bold'}}
                          />
                        </div>
                      </Stack.Item>
                    </Stack> 
                  }

                  {/* Foreman  */}
                  {!props.isProduct && 
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                          <Label className={classNames.label}>{t('foreman')}</Label>
                          <TextField
                            value={props.pmolDetails?.foreman}
                            disabled={props.isProduct}
                            className={classNames.textField}
                            // name='foreman'
                            readOnly
                            style={{background: '#bebebe80', fontWeight: 'bold'}}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  }
                </>
              }

              {props.bulkEdit?.show && 
                <Stack
                  horizontal
                  wrap
                  styles={stackStyles}
                  tokens={stackTokens}
                >
                  <Stack.Item grow={6} styles={stackItemStyles}>
                    <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                      <Label className={classNames.label}>{t('phase')}</Label>
                      <Dropdown 
                        options={[
                          {key: "Before", text: t("before")},
                          {key: "During", text: t("during")},
                          {key: "After", text: t("after")}
                        ]}
                        className={`${classNames.textField}`}
                        onChange={(ev: any, option: any) => {
                          setSelectedPhaseInBulk(option?.key)
                        }}
                      />
                    </div>
                  </Stack.Item>
                </Stack>
              }

              {/* Location  */}
              <Stack
                horizontal
                wrap
                styles={stackStyles}
                tokens={stackTokens}
              >
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                    <Label className={classNames.label}>{t('location')}</Label>
                    <div style={{width: '70%', display: 'flex', gap: 4}}>
                      {/* <TextField
                        value={location?.freeFormAddress}
                        className={classNames.textField}
                        // name='freeFormAddress'
                        style={{width: '100%'}}
                        required
                        readOnly
                      /> */}
                      <AzureAddressAutoComplete
                        onAddressSelected={(address:any) => {
                          if (address) {
                            handleLocationPickedFromField(address)
                          }
                        }}
                        address={location?.freeFormAddress}
                        onChange={function (value: any): void {
                          // console.log('value', value)
                        }}
                      />
                      <PrimaryButton
                        iconProps={mapIconWhite}
                        text={t('map')}
                        onClick={() => setShowMap(true)}
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <ReactAzureMap 
                    openModal={showMap} 
                    setOpenModal={setShowMap}
                    position={getPosition(location?.pos?.lat, location?.pos?.lon)}
                    markedLocation={(position: any, address: any) => handleLocationPicked(position, address)}
                  />
                </Stack.Item>
              </Stack>
                
              <Stack
                horizontal
                wrap
                styles={stackStyles}
                tokens={stackTokens}
                style={{paddingBottom: '24px'}}
              >
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                    <Label className={classNames.label}></Label>
                    <div style={{width: '70%', display: 'flex', gap: 4}}>
                      <TextField
                        value={getPositionValues(location?.pos?.lat)}
                        label={t('Latitude')}
                        className={classNames.textField}
                        style={{width: '100%'}}
                        readOnly
                      />
                      <TextField
                        value={getPositionValues(location?.pos?.lon)}
                        label={t('Longitude')}
                        className={classNames.textField}
                        style={{width: '100%'}}
                        readOnly
                      />
                    </div>
                  </div>
                </Stack.Item>
              </Stack>
                
              {/* Created Date */}
              <Stack
                horizontal
                wrap
                styles={stackStyles}
                tokens={stackTokens}
              >
                <Stack.Item grow={6} styles={stackItemStyles}>
                  <div className={`${classNames.fullWidth}, ${classNames.fields}`}>
                    <Label className={classNames.label}>{t('createdDate')}</Label>
                    <DatePicker
                      id='createdDate'
                      value={props?.bulkEdit?.show ? undefined : new Date(values?.createdDate)}
                      onSelectDate={(date) => {
                        if (date) {
                          const formattedDate: any = formatDateTimeToISOString(date)?.split('T')?.[0]
                          setCreatedDate(formattedDate)
                          setFormData((prev: any) => ({
                            ...prev,
                            createdDate: formattedDate
                          }))
                        }
                      }}
                      // isRequired={true}
                      className={classNames.textField}
                    />
                  </div>
                </Stack.Item>
              </Stack>

              <div className={classNames.renameFooter}>
                <PrimaryButton
                  text={t('update')}
                  style={{ marginRight: '12px'}}
                  onClick={() => {
                    if (!props.bulkEdit?.show) {
                      onUpdate(values)
                    } else {
                      onBulkUpdate()
                    }
                  }}
                />
                <PrimaryButton
                  text={t('cancel')}
                  onClick={() => {
                    props.setIsOpenImgRenameModal && props.setIsOpenImgRenameModal(false);
                    props.setBulkEdit && props.setBulkEdit(false)
                  }}
                />
              </div>
            </form>
          </>
        )
      }}
    />
  )
}

export default PreviewProperties